/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */

/**
 * This reducer is used to store the data of the organization for admin only. It is not used for the user and
 * it does not represent a current organization of the user.
 */

const initialState = {
  model: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  edit: false,
  editSuccess: false,
  editError: null,
};

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "ORGANIZATION_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ORGANIZATION_FETCH_FULFILLED":
      return {
        ...state,
        model: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "ORGANIZATION_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "ORGANIZATION_EDIT":
    case "UPLOAD_BRANDING_FILE":
      return { ...state, edit: true, editSuccess: false, editError: null };

    case "ORGANIZATION_EDIT_FULFILLED":
    case "UPLOAD_BRANDING_FILE_FULFILLED":
      return {
        ...state,
        model: action.payload,

        edit: false,
        editSuccess: true,
      };

    case "ORGANIZATION_EDIT_REJECTED":
    case "UPLOAD_BRANDING_FILE_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        plan: state.plan,
        token: state.token,
        credits: state.credits,
        planCredits: state.planCredits,
        billing: state.billing,
      };

    default:
      return state;
  }

  return state;
}
