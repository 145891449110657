/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2023.
 */

/**
 * This is a reducer for the current organization.
 */

const initialState = {
  model: null,
  subscription: null,
  subscriptionCredits: null,
  credits: null,
  billing: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  billingFetch: false,
  billingFetchSuccess: false,
  billingFetchError: false,
};

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "USER_CHECK_SESSION":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "USER_CHECK_SESSION_FULFILLED":
    case "USER_LOGIN_FULFILLED":
    case "USER_ACTIVATE_PENDING_ACCOUNT_FULFILLED":
    case "USER_SWITCH_ORG_FULFILLED":
    case "USER_REGISTER_FULFILLED":
      return {
        ...state,
        model: action.payload.organization,
        subscription: action.payload.plan,

        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "USER_CHECK_SESSION_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "USER_AUTOLOGIN_FULFILLED":
      return {
        ...state,
        model: action.payload.organization,
        subscription: action.payload.plan,
      };
      break;

    // TODO how to distinguish current organization from admin-edited organization? isAdminEdit?
    case "ORGANIZATION_EDIT":
      return action.isAdminEdit
        ? state
        : { ...state, edit: true, editSuccess: false, editError: null };

    case "UPLOAD_BRANDING_FILE_FULFILLED":
    case "ORGANIZATION_EDIT_FULFILLED":
      return action.isAdminEdit
        ? state
        : {
            ...state,
            model: action.payload.organization,

            edit: false,
            editSuccess: true,
          };

    case "ORGANIZATION_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        subscription: state.subscription,
        credits: state.credits,
        subscriptionCredits: state.subscriptionCredits,
        billing: state.billing,
      };

    case "BILLING_HISTORY_FETCH":
      return {
        ...state,
        billingFetch: true,
        billingFetchSuccess: false,
        billingFetchError: null,
      };

    case "BILLING_HISTORY_FETCH_FULFILLED":
      return {
        ...state,
        billingFetch: false,
        billingFetchSuccess: true,
        billing: action.payload,
      };

    case "BILLING_HISTORY_FETCH_REJECTED":
      return {
        ...state,
        billingFetch: false,
        billingFetchError: action.payload,
      };

    case "SUBSCRIPTION_CREDITS_FETCH_FULFILLED":
      return {
        ...state,
        subscriptionCredits: action.payload
          ? action.payload.currentPeriodCredits
          : 0,
        credits: action.payload,
      };

    case "SUBSCRIPTION_INITIATE_FULFILLED":
      return {
        ...state,
        model: {
          ...state.model,
          pending_payment: 1,
        },
      };

    default:
      return state;
  }

  return state;
}
