/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import { definitions as FrameAndCells } from "./frame_and_cells";
import { definitions as HeaderAndLinks } from "./headers_and_links";
import { definitions as DaysAndDates } from "./days_and_dates";
import { definitions as Slider } from "./slider";
import { definitions as Events } from "./events";
import { definitions as SharePopup } from "./share_popup";
import { definitions as Forms } from "./forms";
import { definitions as RSVP } from "./rsvp";
import { definitions as EventsListening } from "./events_listening";
import { definitions as FullCalendarColors } from "./full_calendar_colors";
import { definitions as WeeklyCalendarColors } from "./weekly_calendar_colors";
import { definitions as Navigation } from "./navigation";
import { definitions as Fonts } from "./fonts";
import { definitions as Badges } from "./badges";
import { definitions as CardsStyles } from "./cards_styles";
import { definitions as DetailedList } from "./detailed_list";
import { definitions as AiSearch } from "./ai_search";
import { definitions as Ctas } from "./ctas";
import { definitions as Bubbles } from "./bubble";
import { definitions as BubblesStyles } from "./bubble_styles";
import { definitions as FeaturedHighlights } from "./featured_highlights";
import { definitions as Branding } from "./branding";
import { definitions as KeywordSearch } from "./keyword_search";

const definitions = [
  FeaturedHighlights,
  FrameAndCells,
  HeaderAndLinks,
  AiSearch,
  DaysAndDates,
  Bubbles,
  Slider,
  Events,
  KeywordSearch,
  SharePopup,
  Ctas,
  Badges,
  Branding,
  Forms,
  RSVP,
  BubblesStyles,
  CardsStyles,
  DetailedList,
  EventsListening,
  FullCalendarColors,
  WeeklyCalendarColors,
  Navigation,
  Fonts,
];

export default definitions;
